<template>
  <div>
    <b-card-code
      title="Update Courier Description"
      :before-change="validationForm"
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-row>
                <!-- translation name -->
                <b-col md="6">
                  <b-form-group label="Name" label-for="name">
                    <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                    >
                      <b-form-input
                        :id="'TranslationName'"
                        v-model="translations.name"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- translation description -->
                <b-col cols="12">
                  <b-form-group label="Description" label-for="description">
                    <validation-provider
                      #default="{ errors }"
                      name="description"
                      rules="required"
                    >
                      <vue-editor
                        :id="'translationDescription'"
                        v-model="translations.description"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>
            </b-col>

            <b-col cols="12">
              <b-button variant="primary" @click.prevent="validationForm"
                >Submit</b-button
              >
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert
      v-height-fade.appear
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
      variant="danger"
    >
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, indexVal) in values" :key="indexVal">
            {{ value }}
          </li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { VueEditor } from 'vue2-editor'
import { heightTransition } from '@core/mixins/ui/transition'
import { required, integer } from '@validations'
export default {
  components: {  
    VueEditor,
    BCardCode,
  },
  
  mixins: [heightTransition],
  props: ['id', 'language'],
  data() {
    return {
      errors_back: '',
      showDismissibleAlert: false,
      required,
      integer,

      translations: {
        locale: '',
        name: '',
        description: '',
      },
    }
  },
  methods: {
    // submit offer API
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          axios
            .put(
              'couriers/' + this.id + '/' + this.language + '/update',
              this.translations
            )
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.back(1)
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
  },
  created() {
    this.translations.locale = this.$route.params.language
    axios
      .get('couriers/' + this.id)
      .then((result) => {
        const data = result.data.data
        for (let index in data.translations) {
          if (this.language == data.translations[index].locale) {
            this.translations.name = data.translations[index].name

            this.translations.description = data.translations[index].description
          }
        }
      })
      .catch((err) => {
        this.errors_back.length = 0
        if (err.response.data.data != null) {
          if (this.isString(err.response.data.data)) {
            this.errors_back.push({
              error: err.response.data.data,
            })
          } else {
            this.errors_back = err.response.data.data
          }
          this.showDismissibleAlert = true
        } else {
          this.errors_back = []
          this.errors_back.push({
            error: 'internal server error',
          })
          this.showDismissibleAlert = true
        }
      })
  },
}
</script>

<style lang="scss"></style>
